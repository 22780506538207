import { Locales } from "@/config/supported-countries";
import { ENABLED_LOCALES } from "@/constants";
import { formatLocale, stripEntry } from "@/utils/helpers";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import {
  ContentfulClientApi,
  ContentfulCollection,
  createClient,
  Entry,
  EntryCollection,
} from "contentful";
import { x86 } from "murmurhash3js";
import { gunzipSync, gzipSync } from "zlib";
import Contentful from "../content-management-service";
import { Footers, Menus } from "@/custom-pages-config";
import { PageMetaDataT } from "@/types/design-system-types";

// TODO: Fix colors for logs

type PageStatus = {
  state: "Draft" | "Changed" | "Published" | "Error" | "Disabled";
  isLive: boolean;
  isReadyForLive: boolean;
  liveData: any; // Cache of compressed data for live
  fieldsHash: string; // Hash fingerprint of all fields (used to compare with actual content)
  refsHash: string; // Hash fingerprint of all references (used to compare with actual content)
  error: any | null; // Error object if any
  randomValue: number; // Random value to force a "Changed" state on the Contentful UI
  isProcessRunning: "Final deploying" | "Live deploying" | "Translating" | false;
};

export type PageEntry = Entry<{
  slug: string;
  title: string;
  description: string;
  components: Array<Reference>;
  scrollSpies?: Array<{ id: string; label: string }>;
  status?: PageStatus;
  menu?: Menus;
  footer?: Footers;
  nPageMetadata?: Entry<PageMetaDataT>;
}>;

type Reference = {
  sys: {
    id: string;
    contentType: { sys: { id: string } };
  };
  fields: any;
  scrollSpyItem?: { title: string; ref: React.RefObject<HTMLDivElement> };
  identifier?: {
    id: string;
    type: string;
    subType: string | null;
    path: string;
  };
};

// -- /preview  --------- Contentful preview + SSR
// -- /live ------------- Contentful published + SSG

const nonprodApp = "https://webdev-global-site.vercel.app"; // nonprod branch app
const prodApp = "https://webdev-global-site-nonprod.vercel.app"; // prod branch app

const pages = {
  /***************************************** NONPROD *****************************************/
  "nonprod-www.cmcmarkets.com/preview/**/*": `${nonprodApp}/neptune/preview/**/*`, // Preview (uncached)
  "nonprod-www.cmcmarkets.com/live/**/*": `${nonprodApp}/neptune/live/**/*`, // Live (uncached)
  "nonprod-www.cmcmarkets.com/**/*": `${nonprodApp}/neptune/live/**/*`, // Live (cached)

  /****************************************** PROD ******************************************/
  "www.cmcmarkets.com/preview/**/*": `${prodApp}/neptune/preview/**/*`, // Preview (uncached)
  "www.cmcmarkets.com/live/**/*": `${prodApp}/neptune/**/*`, // Live (uncached)
  "www.cmcmarkets.com/**/*": `${prodApp}/neptune/**/*`, // Live (cached)

  "www.cmcmarkets.com/en-ca/**/*": `${prodApp}/neptune/en-ca/**/*`, // Live (cached)
  /* ... more regions later (/en-gb, /fr-fr, /pl-pl etc) ... */
};

const assets = {
  /***************************************** NONPROD *****************************************/
  "nonprod-www.cmcmarkets.com/neptune/_next/**/*": `${nonprodApp}/neptune/_next/**/*`, // Static assets (uncached)

  /****************************************** PROD ******************************************/
  "prod-www.cmcmarkets.com/neptune/_next/**/*": `${nonprodApp}/neptune/_next/**/*`, // Static assets (uncached)
  "www.cmcmarkets.com/neptune/_next/**/*": `${prodApp}/neptune/_next/**/*`, // Static assets (cached)
};

const envsANSI = {
  // Coloured logs
  preview: "\x1b[35mPREVIEW\x1b[0m",
  live: "\x1b[32mLIVE\x1b[0m",
  disabled: "\x1b[31mDISABLED\x1b[0m",
};

export default class HeadlessFetcher {
  static client(
    env: "preview" | "published" = "preview"
  ): ContentfulClientApi | undefined {
    if (!process.env.CONTENTFUL_SPACE_ID) {
      console.error("CONTENTFUL_SPACE_ID is not defined");
      // return;
    }

    if (!process.env.CONTENTFUL_ACCESS_TOKEN) {
      console.error("CONTENTFUL_ACCESS_TOKEN is not defined");
      // return;
    }

    if (!process.env.CONTENTFUL_HOST) {
      console.error("CONTENTFUL_HOST is not defined");
      // return;
    }

    if (!process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN) {
      console.error("CONTENTFUL_PREVIEW_ACCESS_TOKEN is not defined");
      // return;
    }

    const space = process.env.CONTENTFUL_SPACE_ID;
    const environment = process.env.CONTENTFUL_ENV;

    /* Draft, Changed and Published */
    const previewToken = process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN;
    const previewHost = process.env.CONTENTFUL_PREVIEW_HOST;

    /* Published only */
    const publishedToken = process.env.CONTENTFUL_ACCESS_TOKEN;
    const publishedHost = process.env.CONTENTFUL_HOST;

    /* Select based on the environment */
    const accessToken = env === "published" ? publishedToken : previewToken;
    const host = env === "published" ? publishedHost : previewHost;

    return createClient({ space, accessToken, host, environment });
  }

  static isLocaleUsable(locale: string) {
    // if (locale === "404") {
    //   console.log(`Locale "${locale}" is not usable`);
    //   return false;
    // }
    // if (locale === "_error") {
    //   console.log(`Locale "${locale}" is not usable`);
    //   return false;
    // }
    // if (locale === "_next") {
    //   console.log(`Locale "${locale}" is not usable`);
    //   return false;
    // }

    if (
      ["_next", "_error", "404", "neptune", "api"].includes(locale) ||
      locale.startsWith("_next")
    ) {
      return false;
    }

    if (!locale.match(/\b[a-z]{2}(-[A-Z]{2})?\b/g)) {
      console.log(`Locale "${locale}" is not in the correct format`);
      return false;
    }

    return locale;
  }

  static async generateHashForPage(pageId: string, locale: string) {
    try {
      console.log("Generating hash for page", locale, pageId);
      const page = await this.client()?.getEntry<{ components: any[] }>(pageId, {
        locale,
        include: 10,
      });
      // Redact the fields that could change but don't affect the page itself (like random values)
      const strippedComponents = stripEntry(page, [
        "randomValue",
        "status",
        "nPageMetadata",
        "taxonomy",
      ]);

      const dataString = JSON.stringify(strippedComponents);
      const dataStringBuffer = new Uint8Array(Buffer.from(dataString, "utf-8"));
      const compressedData = gzipSync(dataStringBuffer);
      const hash = x86.hash32(compressedData.toString("hex"));

      return hash.toString();
    } catch (error) {
      console.error("Failed to generate hash for page", locale, pageId);
    }
  }

  /**
   * Retrieve a single entry from contentful by id
   *
   */
  static async getEntry<T>(
    contentful: "preview" | "published" = "preview",
    id: string,
    locale: Locales = "en",
    options?: any
  ) {
    if (!id) return false;

    return this.client(contentful)?.getEntry<T>(id, {
      include: 10,
      locale: locale,
      ...options,
    });
  }

  /**
   * Get an asset from an ID
   */
  static async getAsset(
    contentful: "preview" | "published" = "preview",
    id: string,
    locale: Locales = "en"
  ) {
    if (!id) return false;

    return this.client(contentful)?.getAsset(id, {
      locale: locale,
    });
  }

  static async getEntries(contentful: "preview" | "published" = "preview", options: any) {
    return this.client(contentful)?.getEntries(options);
  }

  static async getAllMenus(locale: string) {
    if (!this.isLocaleUsable(locale)) return false;
    return this.client()?.getEntries({
      content_type: "nMenu",
      locale,
      include: 3,
      limit: 100,
      // "fields.internalName": menu,
    });
  }

  static async getFooterByName(footer: string, locale: string) {
    if (!this.isLocaleUsable(locale)) return false;

    try {
      const res = await this.client()?.getEntries({
        content_type: "nFooter",
        locale,
        include: 3,
        "fields.internalName": footer,
      });
      return res;
    } catch (e) {
      return null;
    }
  }

  static async getFooterByPageSlug(
    locale: string,
    slug: string,
    contentful: "preview" | "published" = "preview"
  ) {
    if (!this.isLocaleUsable(locale)) {
      return false;
    }

    try {
      slug = slug.startsWith("/") ? slug.slice(1) : slug;
      slug = slug === "home" || slug === "" ? "home" : slug;

      const footerName: any = await this.client(contentful)?.getEntries({
        content_type: "nPage",
        "fields.slug[exists]": true,
        "fields.slug": slug,
        select: "fields.footer",
        locale,
      });

      const footer = await this.client(contentful)?.getEntries({
        content_type: "nFooter",
        locale,
        include: 3,
        "fields.internalName": footerName?.items[0]?.fields?.footer,
      });

      const stripped = stripEntry(footer?.items[0]);

      if (footer?.total) return { items: [stripped] };
    } catch (e) {
      return null;
    }

    return null;
  }

  static async getPlainTextDID(
    contentful: "preview" | "published" = "preview",
    internalName: string,
    locale: Locales,
    currentPageId?: string
  ) {
    try {
      const did: any = await this.client(contentful)?.getEntries({
        content_type: "nDid",
        include: 3,
        limit: 100,
        locale: locale ?? "en",
        "fields.internalName": internalName,
      });
      let plainTextDid = documentToPlainTextString(did?.items?.[0]?.fields?.value);
      const footnoteOverrideVal = did.items[0].fields.footnoteNumberOverride;

      // Used to override footnotes, its an addition on the bottom of the nDid content type
      // running from the custom app
      const override: { number: number } = footnoteOverrideVal.find(
        (override: any) => override.id === currentPageId
      );
      if (plainTextDid === "{{{ FOOTNOTE-OVERRIDE }}}" && override) {
        const footnotes = {
          1: "¹",
          2: "²",
          3: "³",
          4: "⁴",
          5: "⁵",
          6: "⁶",
          7: "⁷",
          8: "⁸",
          9: "⁹",
          10: "¹⁰",
          11: "¹¹",
          12: "¹²",
          13: "¹³",
          14: "¹⁴",
          15: "¹⁵",
        } as const;

        return footnotes[override.number as keyof typeof footnotes];
      }

      return plainTextDid;
    } catch (error) {
      return "";
    }
  }

  // ? Kept just in case, but can remove as it's not used
  static async getAllIcons() {
    return this.client()?.getEntries({
      content_type: "nIcons",
      include: 3,
      limit: 100,
    });
  }

  static async getMenuByName(menu: string, locale: string) {
    if (!this.isLocaleUsable(locale)) {
      return false;
    }

    try {
      const res = await this.client()?.getEntries({
        content_type: "nMenu",
        locale,
        include: 3,
        "fields.internalName": menu,
      });

      return res;
    } catch (e) {
      return null;
    }
  }

  /**
   *
   * @param contentful Contentful server environment [preview | published]
   * @returns
   */
  static async getMenuByPageSlug(
    locale: string,
    slug: string,
    contentful: "preview" | "published" = "preview"
  ) {
    if (!this.isLocaleUsable(locale)) return false;

    slug = slug.startsWith("/") ? slug.slice(1) : slug;
    slug = slug === "home" || slug === "" ? "home" : slug;

    try {
      const menuName: any = await this.client(contentful)?.getEntries({
        content_type: "nPage",
        "fields.slug[exists]": true,
        "fields.slug": slug,
        select: "fields.menu",
        locale,
      });

      const menu = await this.client(contentful)?.getEntries({
        content_type: "nMenu",
        locale,
        include: 3,
        "fields.internalName": menuName?.items[0]?.fields?.menu,
      });

      const stripped = stripEntry(menu?.items[0]);

      if (menu?.total) return { items: [stripped] };
    } catch (e) {
      return null;
    }

    return null;
  }

  static async getMenuNameByPageSlug(locale: string, slug: string) {
    if (!this.isLocaleUsable(locale)) return false;

    const data = await this.client()?.getEntries({
      content_type: "nPage",
      "fields.slug[exists]": true,
      "fields.slug": slug,
      select: "fields.menu",
      locale,
    });

    // @ts-ignore
    if (!data?.items[0]?.fields?.menu) return false;

    // @ts-ignore
    return data.items[0].fields.menu;
  }

  static async getPagesIds(locale: string) {
    if (!this.isLocaleUsable(locale)) return false;
    const data = await this.client()?.getEntries({
      content_type: "nPage",
      "fields.slug[exists]": true,
      select: "fields.slug",
      locale,
    });

    const obj: any = {};

    data?.items.forEach((item: any) => {
      obj[item.fields.slug] = item.sys.id;
    });

    return obj;
  }

  static async getSlugs({
    contentful,
  }: // locale,
  {
    contentful: "preview" | "published";
    // locale: string;
  }): Promise<
    { params: { locale: Locales; dynamic_slug: string | string[] } }[] | false
  > {
    const slugsEntries_partOne:
      | EntryCollection<{
          slug: Record<Locales, string>;
          status: Record<Locales, PageStatus>;
        }>
      | undefined = await this.client(contentful)?.getEntries({
      content_type: "nPage",
      "fields.slug[exists]": true,
      "fields.components[exists]": true,
      select: "fields.slug,fields.status",
      locale: "*",
      limit: 50,
    });

    const slugsEntries_partTwo:
      | EntryCollection<{
          slug: Record<Locales, string>;
          status: Record<Locales, PageStatus>;
        }>
      | undefined = await this.client(contentful)?.getEntries({
      content_type: "nPage",
      "fields.slug[exists]": true,
      "fields.components[exists]": true,
      select: "fields.slug,fields.status",
      locale: "*",
      skip: 50,
      limit: 50,
    });
    const slugsEntries_partThree:
      | EntryCollection<{
          slug: Record<Locales, string>;
          status: Record<Locales, PageStatus>;
        }>
      | undefined = await this.client(contentful)?.getEntries({
      content_type: "nPage",
      "fields.slug[exists]": true,
      "fields.components[exists]": true,
      select: "fields.slug,fields.status",
      locale: "*",
      skip: 100,
      limit: 50,
    });
    const slugsEntries_partFour:
      | EntryCollection<{
          slug: Record<Locales, string>;
          status: Record<Locales, PageStatus>;
        }>
      | undefined = await this.client(contentful)?.getEntries({
      content_type: "nPage",
      "fields.slug[exists]": true,
      "fields.components[exists]": true,
      select: "fields.slug,fields.status",
      locale: "*",
      skip: 150,
      limit: 50,
    });
    const slugsEntries_partFive:
      | EntryCollection<{
          slug: Record<Locales, string>;
          status: Record<Locales, PageStatus>;
        }>
      | undefined = await this.client(contentful)?.getEntries({
      content_type: "nPage",
      "fields.slug[exists]": true,
      "fields.components[exists]": true,
      select: "fields.slug,fields.status",
      locale: "*",
      skip: 200,
      limit: 50,
    });
    const slugsEntries_partSix:
      | EntryCollection<{
          slug: Record<Locales, string>;
          status: Record<Locales, PageStatus>;
        }>
      | undefined = await this.client(contentful)?.getEntries({
      content_type: "nPage",
      "fields.slug[exists]": true,
      "fields.components[exists]": true,
      select: "fields.slug",
      locale: "*",
      skip: 250,
      limit: 50,
    });
    const slugsEntries_partSeven:
      | EntryCollection<{
          slug: Record<Locales, string>;
          status: Record<Locales, PageStatus>;
        }>
      | undefined = await this.client(contentful)?.getEntries({
      content_type: "nPage",
      "fields.slug[exists]": true,
      "fields.components[exists]": true,
      select: "fields.slug,fields.status",
      locale: "*",
      skip: 300,
      limit: 50,
    });

    if (
      !slugsEntries_partOne ||
      !slugsEntries_partTwo ||
      !slugsEntries_partThree ||
      !slugsEntries_partFour ||
      !slugsEntries_partFive ||
      !slugsEntries_partSix ||
      !slugsEntries_partSeven
    ) {
      console.log("Failed to fetch slugs.");
      return false;
    }

    const slugAllEntries = {
      items: [
        ...slugsEntries_partOne.items,
        ...slugsEntries_partTwo.items,
        ...slugsEntries_partThree.items,
        ...slugsEntries_partFour.items,
        ...slugsEntries_partFive.items,
        ...slugsEntries_partSix.items,
        ...slugsEntries_partSeven.items,
      ],
    };

    if (!slugAllEntries) return false;
    const slugs: {
      params: { locale: Locales; dynamic_slug: string | string[] };
    }[] = [];

    slugAllEntries.items.forEach((item) => {
      Object.entries(item.fields.status).forEach(
        ([locale, status]: [string, PageStatus]) => {
          const slug = item.fields.slug[locale as Locales];

          // Only return pages that have live data and are not disabled
          if (slug && status.liveData && status.state !== "Disabled") {
            // Split the slug in the slashes if its more than one segment
            if (slug.split("/").length > 1) {
              slugs.push({
                params: {
                  locale: locale.toLowerCase() as Locales,
                  dynamic_slug: slug.split("/").length > 1 ? slug.split("/") : [slug],
                },
              });
            }
          }
        }
      );
    });

    return slugs;
  }

  static async _getCachedLiveData(locale: Locales, slug: string) {
    if (!this.isLocaleUsable(locale)) return false;

    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

    delay(200);

    // Its always the original, unmasked locale we need when fetching the cached live data
    locale = formatLocale(locale, true);

    const status = await this.getPageStatus(locale, slug);

    const logEntry = `${envsANSI["live"]} \u001b[36m(${locale}/${slug}):\x1b[0m`;

    if (!status) {
      console.log(logEntry, "Failed to fetch page state.");
      return null;
    }

    if (status?.state === "Disabled") {
      console.log(logEntry, "Page is disabled.");
      return null;
    }

    const data: ContentfulCollection<PageEntry> | undefined = await this.client(
      "published"
    )?.getEntries({
      content_type: "nPage",
      locale,
      include: 10,
      "fields.slug": slug,
      select: "fields.status",
    });

    const dataBuffer = data?.items[0]?.fields?.status?.liveData;

    if (!dataBuffer) {
      console.log(logEntry, "Failed to fetch live data. No published data found.");
      return null;
    }

    // Unzip the data
    const zippedDataBuffer = Buffer.from(dataBuffer, "base64");
    const unzippedData = gunzipSync(zippedDataBuffer as any);
    const unzippedDataString = unzippedData.toString("utf-8");

    let parsedData: any;
    try {
      parsedData = JSON.parse(unzippedDataString);
    } catch (e) {
      console.log(logEntry, "Failed to parse live data. Error: ", e);
      return null;
    }

    console.log(logEntry, "Live data fetched successfully.");

    return parsedData;
  }

  static async getPageStatus(
    locale: string,
    slug: string
  ): Promise<PageStatus | false | null | undefined> {
    if (!this.isLocaleUsable(locale)) return false;

    const data: ContentfulCollection<PageEntry> | undefined = await this.client(
      "preview"
    )?.getEntries({
      content_type: "nPage",
      locale,
      include: 10,
      "fields.slug": slug,
      select: "fields.status",
    });

    if (!data?.total) {
      console.log(`(${locale}/${slug}): `, "Failed to fetch page state.");
      return null;
    }

    return data?.items[0]?.fields?.status;
  }

  /**
   *  Retrieve a dynamic page by using the context to determine the locale and the slug
   *
   * @param contentful Contentful server environment [preview | published]
   * @param env SSR/SSG page environment [preview | live]
   * @param locale Locale of the page
   * @param slug Slug of the page
   * @param strip Set this to true to strip out all the unnecessary data (ie sys, metadata etc)
   * @param bypassDisabledStateCheck Set this to true to force fetch pages, even if they are disabled
   */
  static async getPageData({
    contentful = "preview",
    env = "preview",
    slug,
    locale,
    strip = false,
  }: {
    contentful: "preview" | "published";
    env: "preview" | "live";
    slug: string;
    locale: Locales;
    strip?: boolean;
    bypassDisabledStateCheck?: boolean;
  }): Promise<ContentfulCollection<PageEntry> | null> {
    const logEntry = `${envsANSI[env]} \u001b[36m(${locale}/${slug}):\x1b[0m`;

    if (!this.isLocaleUsable(locale)) {
      console.log(logEntry, "Cannot fetch page data! Locale is not usable.");
      return null;
    }

    let data: ContentfulCollection<PageEntry> | undefined;

    if (contentful === "preview") {
      try {
        data = await this.client("preview")?.getEntries({
          content_type: "nPage",
          locale,
          include: 10,
          "fields.slug": slug,
          "fields.components[exists]": true,
        });
      } catch (e) {
        return null;
      }

      if (strip && data?.total) {
        const strippedEntry = stripEntry(data.items[0]);

        // Remove the old live data
        delete strippedEntry.fields.status.liveData;
        delete strippedEntry.fields.status.error;

        const stripped = {
          total: data.total,
          items: [strippedEntry],
        } as any;

        data = stripped;
      }
    }

    if (contentful === "published") {
      try {
        data = await this.client("published")?.getEntries({
          content_type: "nPage",
          locale,
          include: 10,
          "fields.slug": slug,
          "fields.components[exists]": true,
        });
      } catch (e) {
        return null;
      }

      // Interrupt early if no items
      if (!data?.items.length) {
        return null;
      }

      if (strip && data?.total) {
        const contentful = new Contentful();
        const references = await contentful.client.entry.references({
          entryId: data?.items[0].sys.id,
          include: 10,
        });
        const referencedEntries = references.includes?.Entry;

        // Strips out the extra fluff from the JSON to reduce the size
        // Also clears out any references that are in "Draft" state (if Contentful will fix their side at some point and this might not be needed)
        const strippedEntry = stripEntry(
          data.items[0],
          ["randomValue"],
          referencedEntries,
          locale
        );

        delete strippedEntry.fields.status.liveData;
        delete strippedEntry.fields.status.error;

        const stripped = {
          total: data.total,
          items: [strippedEntry],
        } as any;

        // If the stripping removes all of the components because they were "Draft"
        // then return null so the page can 404
        if (!stripped?.items?.[0]?.fields?.components?.length) return null;

        data = stripped;
      }
    }

    if (data?.items[0]?.fields && !data?.items?.[0]?.fields?.title) {
      data.items[0].fields.title = "";
    }

    if (data?.items[0]?.fields && !data?.items?.[0]?.fields?.description) {
      data.items[0].fields.description = "";
    }

    if (data?.total) {
      // Replace all DID keywords with their actual data (for preview it happens in the frontend)
      if (env === "live") {
        let stringData = JSON.stringify(data.items[0]);

        const didRegex = /\{\{\{ DID-(.*?) \}\}\}/g;
        const matches = Array.from(stringData.matchAll(didRegex));

        if (matches.length) {
          // Create a map to store the replacements
          const replacements: Record<string, string> = {};

          // Perform all asynchronous requests in parallel
          await Promise.all(
            matches.map(async (match) => {
              const didInternalName = match[1];

              const plainTextDid = await HeadlessFetcher.getPlainTextDID(
                "preview",
                didInternalName,
                locale
              );
              replacements[match[0]] = plainTextDid || "";
            })
          );

          // Replace all matches in the string in a single pass
          stringData = stringData.replace(didRegex, (match) => replacements[match]);

          try {
            data.items[0] = JSON.parse(stringData);
          } catch (e) {
            console.log(logEntry, "Failed to replace DID keywords. Error: ", e);
          }
        }
      }

      console.log(logEntry, `Data fetched successfully.`);
      return data;
    }

    if (!data) {
      console.log(logEntry, "Cannot fetch page data! Data not found.");
      return null;
    }

    if (!data?.items?.[0]?.fields?.components) {
      console.log(
        logEntry,
        "\x1b[33mCannot fetch page data! No components found.\x1b[0m"
      );
      return null;
    }

    if (data.total) return data;

    console.log(
      `(${locale}/${slug}): `,
      "Cannot fetch page data! No such page found in Contentful."
    );
    return null;
  }

  static async getHrefLangs() {
    // const pageNames = await this.client("published")?.getEntries({
    const pageNames = await this.client()?.getEntries({
      content_type: "nPage",
      include: 10,
      select: "fields.internalName",
    });

    const hrefLangs: any = {};

    if (!pageNames) return;

    await Promise.all(
      pageNames.items.map(async (pageName: any) => {
        const slugsInAllLocales = await Promise.all(
          ENABLED_LOCALES.map(async (locale: string) => {
            const localizedSlug: any = await this.client()?.getEntry(pageName.sys.id, {
              content_type: "nPage",
              locale,
              select: "fields.slug",
            });

            return `${locale.toLowerCase()}/${localizedSlug.fields.slug}`;
          })
        );

        hrefLangs[pageName.fields.internalName] = slugsInAllLocales;
      })
    );

    return hrefLangs;
  }

  static async getAllPagesInLocale(
    locale: string,
    contentful: "preview" | "published" = "preview"
  ) {
    // console.log(locale);
    if (!this.isLocaleUsable(locale)) return false;
    return this.client(contentful)?.getEntries({
      content_type: "nPage",
      locale,
      include: 10,
    });
  }

  static async getAllFAQs(locale: Locales) {
    if (!this.isLocaleUsable(locale)) return false;
    return this.client("preview")?.getEntries({
      content_type: "nFaq",
      locale,
      include: 10,
      limit: 1000,
    });
  }

  static async getKnowledgeHubArticles(
    contentful: "published" | "preview",
    locale: string
  ) {
    // if (!this.isLocaleUsable(locale)) return false;
    return this.client(contentful)?.getEntries({
      content_type: "nPage",
      locale,
      include: 10,
      limit: 30,
      "metadata.tags.sys.id[in]": "learn", // Tagged with "Learn" tag in Contentful
      "fields.slug[exists]": true, // Only pages with slugs, this is for localized pages
      "fields.components[exists]": true, // Only pages with components (to avoid empty pages)
    });
  }

  static async getPageMetadata(
    contentful: "preview" | "published",
    pageId: string
  ): Promise<
    | Entry<{
        metadata: {
          slugs: Record<string, string>;
        };
      }>
    | undefined
  > {
    return this.client(contentful)?.getEntry(`${pageId}_metadata`, {
      include: 1,
    });
  }

  // Fetch all the pages required to build the sitemap for a specific locale
  static async getAllPagesForSitemap(locale: Locales) {
    console.log(`Fetching all pages for sitemap....${locale}`);
    if (!this.isLocaleUsable(locale)) return false;
    // First we get all the available pages for the locale, we need the page ids
    const pages = await this.client("published")?.getEntries({
      content_type: "nPage",
      include: 1,
      locale,
      select: "sys.id",
      limit: 1000,
    });

    // If there are no pages, we return false
    if (!pages) return false;

    // We extract all the page ids and split them into a string
    const pageIds = pages?.items.map((page: any) => page.sys.id);
    // Convert the array of page ids into a string so we can use in the next contentful query
    const pageIdsString = pageIds.join(",");
    // Now we get all the pages with the ids we have (all locales of each page for Hreflangs generation)
    const allPages = await this.client("published")?.getEntries({
      content_type: "nPage",
      locale: "*",
      include: 1,
      "sys.id[in]": pageIdsString,
      select: "fields.slug,fields.internalName,sys.updatedAt",
      limit: 1000,
    });
    return allPages?.items;
  }
}

/************************************************************************************/
/************************************ DEPRECATED ************************************/
/************************************************************************************/

class __deprecatedMethods {
  /**
   * ? Work in progress..
   * * Redact a DID keyword from the page data and replace it with data fetched from Contentful
   */
  static async retrieveDIDs(data: PageEntry) {
    // @ts-ignore
    const str = JSON.stringify(data.items[0]);

    const matches = str.match(/{{{(.*?)}}}/g);

    if (matches?.length) {
      const dids = (
        await Promise.all(
          matches.map(async (match, i) => {
            if (match === "{{{ DID WHATEVER }}}") {
              // @ts-ignore
              const didData = await this.getEntry("2BMgX1CZmogY0nW8woONXW");

              if (didData) return { [match]: JSON.stringify(didData) };
            }
          })
        )
      ).filter(Boolean);

      // @ts-ignore
      data.items[0].fields.dids = dids;
    }
  }

  static async getPageDataByInternalName(locale: string, internalName: string) {
    // Query contentful for the page data
    const data: ContentfulCollection<PageEntry> | undefined =
      // @ts-ignore
      await this.client()?.getEntries({
        content_type: "nPage",
        locale,
        include: 10,
        "fields.internalName": internalName,
      });

    if (data?.total) return data;

    return "No such page found in Contentful.";
  }

  static async hasComponents(locale: string, name: string) {
    // @ts-ignore
    const data = await this.client()?.getEntries({
      content_type: "nPage",
      locale,
      include: 10,
      "fields.internalName": name,
      // "fields.slug": slug,
      "fields.components[exists]": true,
    });

    // console.log(data);

    return data?.total ? true : false;
  }

  /**
   *  @deprecated
   */
  static async getPageComponentsBySlug(locale: string, slug: string) {
    const page = await (async () => {
      // @ts-ignore
      const data = await this.client()?.getEntries({
        content_type: "nPage",
        locale,
        include: 10,
        "fields.slug": slug,
        select: "fields.ready,sys.id",
      });

      return {
        // @ts-ignore
        isReady: data?.items[0]?.fields?.ready,
        id: data?.items[0]?.sys?.id,
      };
    })();

    if (page.isReady) {
      // Query contentful for the page data
      const data: ContentfulCollection<PageEntry> | undefined =
        // @ts-ignore
        await this.client()?.getEntries({
          content_type: "nPage",
          locale,
          include: 10,
          "fields.slug": slug,
          "fields.components[exists]": true, // This prevents from falling back to en components
          select: "fields.components,sys",
        });

      await fetch(`${process.env.URL}/api/cache-page-json`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: page.id, locale, data }),
      });

      console.log("Live data fetched..");

      if (data?.total) return data;
    } else {
      const res = await fetch(`${process.env.URL}/api/cache-page-json`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Cache data fetched..");

      const cacheData = await res.json();
      if (cacheData.total) return cacheData;
    }

    return null;
  }
}
